/* 美化模态框内容 */
.actuarial-details {
    padding: 20px;
}

.actuarial-item {
    margin-bottom: 10px; /* 默认底部间距 */
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    padding: 10px;
}

.actuarial-item:nth-child(odd) {
    background-color: #f9f9f9; /* 奇数行背景色 */
}

.actuarial-item:nth-child(even) {
    background-color: #e9e9e9; /* 偶数行背景色 */
}

.actuarial-item.additional-spacing {
    margin-bottom: 70px; /* 额外的底部间距 */
}

.actuarial-label {
    font-weight: bold;
    color: #333;
}

.actuarial-content {
    color: #666;
}

.actuarial-content.negative {
    color: red; /* 负数值颜色 */
}

.actuarial-content.positive {
    color: green; /* 非负数值颜色 */
}
