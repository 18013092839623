/* Root font size */
:root {
    font-size: 16px; /* 整体放大字体 */
}

/* Center the loading spinner */
.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 1.5rem;
    color: #1890ff;
}

/* Container styling */
.user-second-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f9f9f9;
    padding: 20px;
}

/* Card styling */
.user-second-card {
    width: 100%;
    max-width: 990px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: all 0.3s ease;
}

.user-second-card:hover {
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

/* Title styling */
.user-second-title {
    text-align: center;
    font-size: 1.5rem; /* 增大标题字体 */
    margin-bottom: 20px;
    color: #333;
}

/* Descriptions styling */
.user-second-descriptions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.description-item {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background: #fafafa;
    border-radius: 5px;
    transition: background 0.3s ease;
}

.description-item:hover {
    background: #f0f0f0;
}

.description-label {
    font-weight: bold;
    color: #555;
    font-size: 1rem; /* 墛大标签字体 */
    line-height: 1.5; /* 行高 */
}

.description-content {
    color: #555;
    font-size: 1rem; /* 墛大内容字体 */
    line-height: 1.5; /* 行高 */
}

/* Positive and negative value styling */
.description-content.positive {
    color: green;
}

.description-content.negative {
    color: red;
}

.button-container {
    display: flex; /* 使用 Flexbox */
    justify-content: space-between; /* 水平排列并在两端对齐 */
    align-items: center; /* 垂直居中 */
    margin: 20px auto 0;
    width: 100%; /* 确保按钮容器的宽度为 100% */
    max-width: 500px; /* 可选：设置最大宽度以限制按钮容器的大小 */
}

/* 保持按钮样式不变 */
.password-change-button {
    flex: 1; /* 使按钮在容器中平分空间 */
    font-size: 1.2rem; /* 墛大按钮字体 */
    padding: 10px 30px; /* 墛大内边距 */
    color: white;
    background-color: #1890ff;
    border: none;
    border-radius: 25px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    margin-right: 10px; /* 添加右边距 */
}

.password-change-button:hover {
    background-color: #40a9ff;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.actuarial-details-button {
    flex: 1; /* 使按钮在容器中平分空间 */
    font-size: 1.2rem; /* 墛大按钮字体 */
    padding: 10px 30px; /* 墛大内边距 */
    color: white;
    background-color: #1890ff;
    border: none;
    border-radius: 25px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.actuarial-details-button:hover {
    background-color: #40a9ff;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

/* Password change form styling */
.password-change-form {
    .ant-form-item {
        margin-bottom: 20px; /* 墛加每个表单项之间的间距 */
    }
}

/* Responsive styling */
@media (max-width: 768px) {
    :root {
        font-size: 14px; /* 缩小移动端整体字体 */
    }

    .description-label, .description-content {
        line-height: 1.2; /* 缩小移动端行间距 */
    }

    .user-second-descriptions {
        grid-template-columns: 1fr;
        gap: 5px; /* 缩小移动端description-item之间的间距 */
    }

    .description-item {
        flex-direction: column;
        align-items: flex-start;
    }

    .button-container {
        flex-direction: column; /* 垂直排列按钮 */
        align-items: stretch; /* 使按钮在容器中拉伸到最大宽度 */
    }

    .password-change-button1, .actuarial-details-button {
        margin: 5px 0; /* 垂直方向上的间距 */
    }
}
