.login {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: center/cover url('~@/assets/images/login.jpg');

  .login-header {
    // width: 200px;
    // height: 60px;
    display: flex;
    justify-content: center;
  }

  .login-logo {
    position: relative;
    top: 20px;
    left: 20px;
    cursor: pointer;
    height: 8%;
  }

  .login-title {
    text-align: center;
    font-size: 1.5em; // 增大字体
    line-height: 1.5; // 调整行高
    font-weight: 800;
    color: #303030;
    margin-top: 0px; // 增加上边距
    margin-bottom: 5px; // 增加下边距
    padding: 10px; // 增加内边距
    border-bottom-width: 10px;
  }

  .login-container {
    width: 440px;
    height: 360px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 50px rgb(0 0 0 / 10%);
  }

  .login-checkbox-label {
    color: #1890ff;
  }

  .login-help {
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
    color: #666;

    p {
      margin-bottom: 8px;
    }

    button {
      font-size: 14px;
      color: #1890ff;
    }
  }
  .login-help a {
    text-decoration: underline !important; /* 强制添加下划线 */
    color: #1890ff;
  }


  .underline-button {
    text-decoration: underline !important;
  }

  .login-footer {
    text-align: center;
    font-size: 0.8em;
    line-height: 1.5; // 调整行高
    font-weight: 800;
    color: #999999;
    margin-bottom: 20px; // 增加下边距
    padding: 10px; // 增加内边距
  }

}