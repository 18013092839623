.custom-change-password-modal .ant-modal-content {
    border-radius: 15px;
    padding: 30px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    background-color: #fefefe;
}

.custom-change-password-modal .ant-modal-header {
    border-bottom: none;
    text-align: center;
    padding-bottom: 10px;
}

.custom-change-password-modal .ant-modal-title {
    font-size: 1.5rem;
    color: #333;
    font-weight: bold;
}

.custom-password-change-form .custom-form-item {
    margin-bottom: 20px; /* 增加每个表单项之间的间距 */
    display: flex;
    flex-direction: column;
}

.custom-password-change-form .ant-form-item-label > label {
    font-weight: bold;
    color: #444;
    font-size: 1rem;
}

.custom-password-change-form .custom-input {
    border-radius: 5px;
    padding: 10px;
    font-size: 1rem;
}

.spacer {
    height: 20px; /* 设置间隔高度 */
}

.custom-modal-footer {
    display: flex;
    justify-content: center; /* 中心对齐 */
    gap: 20px; /* 按钮之间的间距 */
    margin-top: 30px;
}

.custom-modal-submit-button,
.custom-modal-cancel-button {
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    text-align: center; /* 确保按钮文字居中 */
    flex: 1; /* 使按钮占据相同的宽度 */
    margin: 0 10px; /* 添加按钮之间的间隔 */
    padding: 10px 30px; /* 增大内边距 */
    font-size: 1.1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    min-width: 100px; /* 设置最小宽度以确保居中 */
    display: inline-flex;
}

.custom-modal-submit-button {
    background-color: #4caf50;
    border: none;
    color: white;
}

.custom-modal-submit-button:hover {
    background-color: #45a049;
}

.custom-modal-cancel-button {
    background-color: #f44336;
    border: none;
    color: white;
}

.custom-modal-cancel-button:hover {
    background-color: #e53935;
}
