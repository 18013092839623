/* help.css */

.help-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
  padding: 20px;
}

.help-card {
  max-width: 400px;
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.help-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.help-description {
  font-size: 14px;
  color: #666;
  text-align: center;
  margin-bottom: 20px;
}

.help-form-item {
  margin-bottom: 16px;
}

.help-input {
  height: 40px;
  padding: 8px;
  font-size: 16px;
}

.help-button-primary {
  height: 40px;
  font-size: 16px;
  font-weight: bold;
}

.help-button-primary[disabled] {
  background-color: #d9d9d9;
  border-color: #d9d9d9;
}

/* Modal styles for Captcha */
.help-captcha-modal-title {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

.help-captcha-modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

/* Additional styles for timer */
.help-card .timer-text {
  color: #888;
  font-size: 14px;
  margin-left: 8px;
}

.help-warning {
  color: red;
}

/* Media query for mobile responsiveness */
@media (max-width: 480px) {
  .help-card {
    max-width: 100%;
    padding: 15px;
  }

  .help-title {
    font-size: 20px;
  }

  .help-input,
  .help-button-primary {
    font-size: 14px;
    height: 36px;
  }
}
