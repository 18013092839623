.ant-layout {
  height: 100%;
}

.header {
  padding: 0;
}

.logo {
  width: 165px;
  height: 64px;
}

.layout-content {
  overflow-y: auto;
}



.ant-layout-header {.user-info {
  position: absolute;
  right: 0;
  top: 0;
  padding-right: 20px;
  color: #fff;

  .user-name {
    margin-right: 20px;
  }

  .user-logout {
    display: inline-block;
    cursor: pointer;
  }
}
  padding: 0 !important;
}

.layout-title {
  text-align: left;
  font-size: medium;
  line-height: 70px;
  padding-left: 10px;
  font-weight: 600;
  color: #ffffff;
}

@media (max-width: 768px) {
  .custom-collapsed-sider {
    --collapsed-width: 35px;
  }
}

@media (min-width: 769px) {
  .custom-collapsed-sider {
    --collapsed-width: 80px;
  }
}

/* 移动端样式 */
@media (max-width: 768px) {
  .layout-title {
    font-size: 11px;
  }

  .user-name,
  .user-logout {
    font-size: 11px;
  }
}

@media (max-width: 768px) {
  .custom-collapsed-sider {
    --collapsed-width: 35px;
  }

  .header {
    height: 40px; /* 移动端高度 */
  }

  .logo {
    height: 48px; /* 调整 logo 高度以适应 Header */
  }

  .layout-title {
    font-size: 14px;
    line-height: 40px; /* 调整行高以适应 Header */
    padding-left: 5px;
  }

  .user-info {
    padding-right: 10px;
    align-items: center; /* 垂直居中 */

    .user-name {
      font-size: 12px;
      margin-right: 10px;
      line-height: 40px; /* 调整行高以适应 Header */
    }

    .user-logout {
      font-size: 12px;
      line-height: 40px; /* 调整行高以适应 Header */
    }
  }
}
